import {
  FormControl
} from '@angular/forms';
import {
  Component,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import {
  BranchActionModalComponent
} from '../../modal-dialogs/branch-action-modal/branch-action-modal.component';
import {
  BranchModel, CartModul
} from '../../models';
import {
  BranchService
} from '../../services/branch.service';
import {
  CartService
} from '../../services/cart.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../..';
import { SearchDevicesComponent } from '../../modal-dialogs/search-devices/search-devices.component';
import { CategoryService } from '../../services/category.service';
import { CategoryModel } from '../../models/category.modal';
import { mainCategories } from '../../constants/main-categories.constants';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {

  //navigation for PRODUCTION
  // smartphone: string = '61a9eb5f38d7ac791890171b';
  // tablet: string = '61a9eb5f38d7ac791890171f';
  // notebook: string = '61a9eb5f38d7ac7918901722';
  // desktop: string = '61a9eb5f38d7ac7918901725';
  // gear: string = '61a9eb5f38d7ac791890172a';
  // display: string = '61a9eb5f38d7ac791890172d';
  // case: string = '61a9eb5f38d7ac7918901731';
  // power : string = '61c462f89b493939ee8281ff';
  // headphones: string = '61c4631e9b493939ee828208';
  // miceandkeyboard: string = '61c4633b9b493939ee828211';

  //navigation for TEST environment
  // smartphone: string = '618e550db26c731cc4f03c85';
  // tablet: string = '618e550db26c731cc4f03c89';
  // notebook: string = '618e550db26c731cc4f03c8c';
  // desktop: string = '618e550db26c731cc4f03c8f';
  // gear: string = '618e550db26c731cc4f03c92';
  // display: string = '6193a0c18e782d55bb0a549c';
  // case: string = '6193a0c18e782d55bb0a549f';
  // power : string = '61c462f89b493939ee8281ff';
  // headphones: string = '61c4631e9b493939ee828208';
  // miceandkeyboard: string = '61c4633b9b493939ee828211';

  cartProducts: CartModul[] = [];
  branchList: BranchModel[] = [];
  public dialogConfig = new MatDialogConfig();
  private searcgDialogConfig = new MatDialogConfig();
  checkItemsInChart: [] = [];
  branchId = new FormControl();
  numberOfItemsInCartByBranch: [] = [];
  public productsQtySum: number;
  itemsInCart;
  categories: any;

  constructor(
    private branchService: BranchService,
    public cartService: CartService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private categoryService: CategoryService
  ){}

  ngOnInit() {
    this.getBranches();
    this.getItemsNumber();

    this.cartService.getCart().subscribe(res => {
      if (res.items.length > 0) {
        const products = res.items;
        this.productsQtySum = products.reduce((a, b) => a + b.qty, 0);
        this.cartService.orders = this.productsQtySum;
        this.cartService.order();
      }
     })
    }

    getItemsNumber() {
    this.cartService.ordersChanged.subscribe((items) => (this.itemsInCart = items));
    this.getCategories();
  }

  logout(): void {
    this.authService.logout();
  }

  private getCategories(): void {
      this.categoryService.getCategory().subscribe((res: any) => {

        const accessories = res.docs.filter(cat => cat?.name !== 'Services' && !cat?.parent && !mainCategories.includes(cat?.name)).map(category => category?._id)

         this.categories = res.docs.reduce((acc: Record<string, string>, category) => {
          if(mainCategories.includes(category?.name)){
            acc[category.name.toLowerCase()] = category._id;
          }
          return acc;
        }, {});

        this.categories.accessories = accessories;

      })
  }

  getBranches() {
    this.branchService.getAllBranches(1, 10000)
      .subscribe(branches => {
        this.branchList = branches.docs
        this.branchId.patchValue(this.branchList[0]);
        this.branchService.branchSelected.next(this.branchList[0]._id);
        this.branchService.branchSelectedCompanyID.next(this.branchList[0].company_id)
        this.branchService.companyID = this.branchList[0]?.company_id.toString();
        // sessionStorage.setItem('compID', this.branchList[0]?.company_id.toString());
      });
  }


  branchSelected(branchId) {
    // sessionStorage.setItem('compID', branchId?.value?.company_id);
    this.branchService.companyID = branchId?.value?.company_id;
    this.cartService.getCart().subscribe(res => {
      if (res.items.length > 0) {
        const products = res.items;
        this.productsQtySum = products.reduce((a, b) => a + b.qty, 0);
        this.cartService.orders = this.productsQtySum;
        this.cartService.order();
        if (branchId.value?._id) {
          this.dialogConfig.width = '600px';
          this.dialogConfig.height = '324px';
          const branchActionDialog = this.dialog.open(BranchActionModalComponent, this.dialogConfig);
          branchActionDialog.afterClosed().subscribe(res => {
            if (res) {
              this.cartService.clearCart().subscribe();
              this.branchService.branchSelected.next(branchId.value?._id);
              this.branchService.branchSelectedCompanyID.next(branchId.value?.company_id);
              this.router.navigate(['/home']);
              this.cartService.orders = 0;
              this.cartService.order();
            }
          });
        }
      } else {
        this.branchService.branchSelected.next(branchId.value?._id);
        this.branchService.branchSelectedCompanyID.next(branchId.value?.company_id);
        this.router.navigate(['/home']);
      }
    })
  }


  checkCartStatus() {
    // if(this.cartService.getSingleOrBulk() === 'single') {
    //   this.toastr.warning(`Cart preview is not available for personalized order`);
    // } else {
    //   this.cartService.getCart().subscribe(cartRes => {
    //     if (cartRes) {
    //       this.cartProducts = cartRes.items;
    //       this.productsQtySum = this.cartProducts.reduce((a, b) => a + b.qty, 0);
    //       if (this.productsQtySum === 0) {
    //         this.toastr.warning(`There's no items in cart. Add item to proceed with the order`)
    //       } else {
    //         this.router.navigate(['cart'])
    //       }
    //     }
    //   });
    // }
   
    this.cartService.getCart().subscribe(cartRes => {
        if (cartRes) {
          this.cartProducts = cartRes.items;
          this.productsQtySum = this.cartProducts.reduce((a, b) => a + b.qty, 0);
          if (this.productsQtySum === 0) {
            this.toastr.warning(`There's no items in cart. Add item to proceed with the order`)
          } else {
            this.router.navigate(['cart'])
          }
        }
      });
  }


  searchDevices() {
    this.searcgDialogConfig.width = '100%';
    this.searcgDialogConfig.maxWidth = '100vw';
    this.searcgDialogConfig.height = '80px';
    this.searcgDialogConfig.maxHeight = '375px';
    this.searcgDialogConfig.panelClass = 'search-custom'
    this.searcgDialogConfig.position = {
      'top': '0'
    };

    const serchDevicesModal = this.dialog.open(SearchDevicesComponent, this.searcgDialogConfig);

}


}
