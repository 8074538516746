
<div class="navigation-grid bck-black parent-height-65">
  <div class="left-nav">
    <a [routerLink]="['']"><img src="../../../../assets/img/1-navigation/ordering-nav-logo.svg" alt="ordering-logo"></a>
  </div>
  <div class="center-nav">
    <div>
      <a [routerLink]="['/products']" [state]="{ categorie: categories?.smartphone }">Smartphones</a>
      <a [routerLink]="['/products']" [state]="{ categorie: categories?.tablet }">Tablets</a>
      <a [routerLink]="['/products']" [state]="{ categorie: categories?.notebook }">Notebooks</a>
      <a [routerLink]="['/products']" [state]="{ categorie: categories?.desktop }">Desktop</a>
      <a [routerLink]="['/products']" [state]="{ categorie: categories?.accessories  }">Accessories</a>
    </div>
  </div>

  <div class="right-nav">
    <div class="account-icons-grid mb-2">
      <div>
        <!-- <span class="material-icons mat-icons">search</span> -->
        <span><img class="pointer custom-overlay" (click)="searchDevices()" src="../../../../assets/img/1-navigation/new-search-icon.svg" alt=""></span>

       </div>
      <div>
        <!-- <img class="pointer"  (click)="checkCartStatus()"  src="../../../../assets/img/1-navigation/icons/chart-icon.svg" alt="chart-icon"> -->
        <span class="pointer" (click)="checkCartStatus()"><img src="../../../../assets/img/1-navigation/new-cart-icon.svg" alt=""></span>
        <!-- <span class="cart-product-qty-intex">7</span></div> -->
        <!-- <span class="badge badge-warning" id="lblCartCount" *ngIf="cartService.currentQty  | async">{{cartService.currentQty}} </span> -->
        <span class="badge badge-warning" id="lblCartCount" *ngIf="itemsInCart > 0">{{itemsInCart}} </span>
        </div>


     <div>
      <div class="custom-menu dropdown">
        <span class="pointer"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img  src="../../../../assets/img/1-navigation/new-admin-icon.svg" alt="">
        </span>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" [routerLink]="['account/info']">Settings</a>
          <a class="dropdown-item" (click)="logout()"><span>Log off  <img class="log-out-icon" src="../../../../assets/img/1-navigation/icons/header-sing-out-icon.svg" alt=""></span></a>
        </div>
      </div>
    </div>
    </div>

    <mat-form-field appearance="outline" class="custom-navbar-select-box">
      <mat-label class="custom-mat-label">Choose Branch</mat-label>
      <mat-select
      panelClass="full-content"
      (selectionChange)="branchSelected(branchId)"
      [formControl]="branchId"
      >
        <mat-option *ngFor="let branch of branchList" [value]="branch">
          {{branch?.name}}
        </mat-option>
        <!-- <mat-option *ngFor="let branch of branchList" [value]="branch?._id" (onSelectionChange)="companyId($event, branch.company_id)">
          {{branch?.name}}
        </mat-option> -->
      </mat-select>
    </mat-form-field>

  </div>
</div>


