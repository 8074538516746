import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'lissa-order-portal';

  constructor() { }

  ngOnInit(): void {
    let res = new URL(window.location.href);
    let user = res.searchParams.get('user');
    let redirected = res.searchParams.get('redirected');

    if (user) {
      localStorage.setItem("current_user", JSON.stringify({...JSON.parse(user), redirected}));
    }

  }
}
