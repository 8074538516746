import { Injectable } from "@angular/core";
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/internal/operators";
import { AuthenticationService } from "../services";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public company_id;
  public loggedUser;
  public redirectedFrom;
  constructor(public auth: AuthenticationService, public router: Router, public route: ActivatedRoute) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem('current_user')) {
      this.loggedUser = JSON.parse(localStorage.getItem('current_user'))?.user;
      this.redirectedFrom = JSON.parse(localStorage.getItem('current_user'))?.redirected;
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
        ...(this.loggedUser?.company_id && {'x-forwarded-company-id': `${this.loggedUser?.company_id}`}),
        ...(this.redirectedFrom === "asset" && {'forwarded-from-asset': 'true'}),
      },
    }
    );

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(["/login"]);
            }
          }
        },
      ),
    );
  }
}
