import { throwError, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModul } from "../models";

@Injectable()
export class AuthenticationService {
  loggedUser;
  _userActionOccured: Subject<void> = new Subject();
  private backendUrl = `${environment.authMS}`;

  // public sm_company_id;
  constructor(private router: Router, private http: HttpClient) {}
  get userActionOccured(): Observable<void>{ return this._userActionOccured.asObservable()};
  notifyUserAction() {
    this._userActionOccured.next();
  }
  isAuthenticated() {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) return true;
    return false;
  }

  public getToken(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.token;
    }
    return null;
  }

  public getCurrentUserId(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.id;
    }
    return null;
  }

    // http://api2.lissa-ems-test.com/api/ast/device
  login(data): Observable<any> {
    return this.http.post(`${this.backendUrl}auth/login`, data).pipe(catchError((error: any) => throwError(error)));
  }

  signup(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/signup`, data).pipe(catchError((error: any) => throwError(error)));
  }

  logout() {
    localStorage.removeItem("current_user");
    localStorage.removeItem("singleOrBulk");
    localStorage.removeItem("orderInfo");
    this.router.navigate(["/login"]);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http
      .post(`${this.backendUrl}auth/forgot-password`, { email: email })
      .pipe(catchError((error: any) => throwError(error)));
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http
      .put(`${this.backendUrl}auth/forgot-password`, { reset_password_token: token, password: password })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.backendUrl}/user`).pipe(catchError((error: any) => throwError(error)));
  }

  twoFAAuthentication(code, user): Observable<any> {
    let pinCode = code?.sms;
    return this.http.post(`${this.backendUrl}auth/login/2fa/submit/${pinCode}`, user).pipe(catchError((error: any) => throwError(error)));   
  }

  twoFAAuthenticationEmailSend(user):Observable <any> {
    return this.http.post(`${this.backendUrl}auth/login/2fa/send-via-email` , user).pipe(catchError((error: any) => throwError(error)));
  }
  
}
